<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="dataTable"
			:options.sync="options"
			:server-items-length="totalDados"
			:loading="loading"
			:footer-props="{
				'items-per-page-options': [5, 50, 100],
			}"
			class="elevation-0  tabela"
		>
			<template v-slot:[`item.mensagem`]="{ item }">
				{{ item.mensagem | strippedContent }}
			</template>

			<template v-slot:[`item.actions`]="{ item }">
				<!-- <v-icon
					class="mr-2"
					@click="editItem(item)"
					color="blue"
					size="22"
					v-if="!item.load_action"
					title="Editar"
				>
					mdi-pencil
				</v-icon> -->
				<v-icon
					@click="deleteItem(item)"
					color="grey"
					size="30"
					v-if="!item.load_action && !item.ativo"
					title="Desativado"
				>
					mdi-toggle-switch-off-outline
				</v-icon>
				<v-icon
					@click="deleteItem(item)"
					color="primary"
					size="30"
					v-if="!item.load_action && item.ativo"
					title="Ativado"
				>
					mdi-toggle-switch
				</v-icon>
				<v-progress-circular
					color="grey"
					indeterminate
					size="20"
					v-if="item.load_action"
				/>
			</template>
		</v-data-table>
	</div>
</template>

<script>
export default {
	props: {
		ano: Number,
		fornecedor: Array,
		updateSuccess: {
			type: Object,
			default: () => null,
		},
	},
	data() {
		return {
			totalDados: 0,
			dataTable: [],
			loading: false,
			options: {
				page: 1,
				itemsPerPage: 5,
				multiSort: true,
				mustSort: false,
				sortBy: ["ano"],
				sortDesc: [false],
			},
			headers: [
				{ text: "Ano", value: "ano", align: "center" },
				{
					text: "Fornecedor",
					align: "center",
					value: "fornecedor",
				},

				{
					text: "Mensagem",
					value: "mensagem",
					align: "center",
					sortable: false,
				},
				{
					text: "Ações",
					value: "actions",
					sortable: false,
					align: "center",
				},
			],
		};
	},

	methods: {
		async dialogConfirm({ title, text }) {
			const _dialogConfirm = await this.$dialog.confirm({
				text: text,
				title: title,
				actions: [
					{ text: "CANCELAR", color: "red" },
					{ text: "OK", color: "teal" },
				],

				color: "teal lighten-2",
			});

			const _confirm = await _dialogConfirm.wait();

			return _confirm == "OK";
		},
		async getDataFromApi() {
			this.loading = true;
			const { sortBy, sortDesc, page, itemsPerPage } = this.options;
			console.log("=>", sortBy, sortDesc);

			const _response = await this.$http().post(
				"/simulacao/informacoesGerais/select",
				{
					id_fornecedor: this.$props.fornecedor.map((e) => e.value),
					dt_inicial: this.ano != null ? +this.ano + "-01-01" : "",
					offset: (page - 1) * itemsPerPage,
					limit: itemsPerPage,
					total: this.totalDados,
					sortBy: sortBy,
					sortDesc: sortDesc,
				}
			);

			if ((_response || { status: 500 }).status != 200) {
				this.$dialog.notify.error(
					"Falha ao cadastrar o benefício, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				this.loading = false;
				return null;
			}

			this.dataTable = _response.data.dados;
			this.totalDados = _response.data.total[0].total;
			this.loading = false;

			//console.log("data=>", _response);
		},
		async deleteItem(item) {
			const _index = this.dataTable.findIndex((p) => p.key == item.key);
			const _msg = item.ativo ? "desativação" : "ativação";

			const _confirm = await this.dialogConfirm({
				text: `Confirma a ${_msg} do fornecedor (${item.fornecedor}) do ano ${item.ano}?`,
				title: _msg.toUpperCase(),
			});

			if (!_confirm) return;

			item.load_action = true;

			const _response = await this.$http().post(
				"/simulacao/informacoesGerais/update",
				{
					id_informacao: item.id_informacao,
					id_fornecedor: item.id_fornecedor,
					dt_inicial: this.ano + "-01-01",
					ativo: !item.ativo,
					mensagem: null,
					tipo: "ativacao"

				}
			);

			if ((_response || { status: 500 }).status != 200) {
				this.$dialog.notify.error(
					`Falha na ${_msg} do benefício, por favor, tente novamente.`,
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				item.load_action = false;
				return null;
			}
			await this.getDataFromApi();
			item.ativo = !item.ativo;
			item.load_action = false;
		},
		editItem(item) {
			this.$emit("editItem", item);
		},
	},
	filters: {
		strippedContent: function(string) {
			return string.replace(/<\/?[^>]+>/gi, " ").substring(0, 40) + "...";
		},
	},
	watch: {
		options: {
			handler() {
				console.log("=>f0:", this.fornecedor);
				if (this.fornecedor != null) this.getDataFromApi();
			},
			deep: true,
		},
		fornecedor: {
			handler() {
				console.log("=>f11:", this.fornecedor);
				if (this.fornecedor != null) this.getDataFromApi();
			},
			deep: true,
		},
	},
	events: {
		updateItem: function(item) {
			console.log("updateItem", item);
		},
	},
};
</script>

<style></style>
