<template>
	<v-container fluid class=" py-0">


		<Filtros
			@filtrar="filtrarResumo"
			@modalOpen="openModal"
			@modalImportOpen="(v) => (modalImportOpen = v)"
			@exportar="exportar"
			class="mb-4"
		/>

		<InformacoesGeraisImportacao
			v-model="modalImportOpen"
			@filtrar="filtrarResumo"
		/>

		<Modal
			v-model="modalAdd"
			:title="
				(itemUpdate == null ? 'Adicionar' : 'Atualizar') + ' benefício'
			"
			:fullscreen="false"
			:maxWidth="'70%'"
		>
			<template slot="body" v-if="modalAdd">
				<InformacoesGeraisAdd
					@modalClose="modalAdd = false"
					@filtrar="filtrarResumo"
					:fornecedor="fornecedor['add']"
					:ano="ano['add']"
					:itemUpdate="itemUpdate"
				/>
			</template>
		</Modal>
		<v-row>
			<v-col cols="12">
				<InformacoesGeraisLista
					:fornecedor="fornecedor['list']"
					:ano="ano['list']"
					@modalOpen="openModal"
					@editItem="editItem"
				/>
			</v-col>
			<Overlay :overlay="load" />
		</v-row>
	</v-container>
</template>

<script>
import Modal from "./../../components/Modal.vue";
import Filtros from "./_components/Filtros.vue";
import InformacoesGeraisAdd from "./_components/InformacoesGeraisAdd.vue";
import InformacoesGeraisLista from "./_components/InformacoesGeraisLista.vue";
import InformacoesGeraisImportacao from "./_components/InformacoesGeraisImportacao.vue";
import Overlay from "../simulacao/resumo/_components/Overlay.vue";

export default {
	components: {
		Filtros,
		Modal,
		InformacoesGeraisAdd,
		// ./_components/InformacoesGeraisLista.vue
		InformacoesGeraisLista,
		InformacoesGeraisImportacao,
		Overlay,
	},
	data() {
		return {
			ano: {
				list: null,
				add: null,
			},
			fornecedor: {
				list: null,
				add: null,
			},
			modalAdd: false,
			modalImportOpen: false,
			itemUpdate: null,
			load: false,
		};
	},
	methods: {
		setData(area, filtros) {
			this.ano[area] = null;
			this.fornecedor[area] = null;
			this.itemUpdate = null;

			return new Promise((resolve) =>
				setTimeout(() => {
					this.ano[area] = filtros.ano;
					this.fornecedor[area] = filtros.fornecedor;
					resolve(true);
				}, 200)
			);
		},
		async filtrarResumo(filtros) {
			console.log("filtrar=>", filtros);
			await this.setData("list", filtros);
		},
		async openModal(filtros) {
			await this.setData("add", filtros);
			this.modalAdd = true;
		},
		async editItem(item) {
			this.itemUpdate = item;
			this.modalAdd = true;
		},
		async exportar(filtros) {
			//console.log(filtros);

			this.load = true;

			var response = await this.$http().post(
				"/simulacao/informacoesGerais/exportar",
				{
					data: {
						ano: filtros.ano,
					},
				}
			);

			if ((response || { status: 500 }).status != 200) {
				this.$dialog.notify.error(
					"Falha ao exportar o benefício, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				this.load = false;
				return null;
			}

			//console.log("response=>", response);

			window.location = this.$http("baseURL") + response.data.url;

			this.load = false;
		},
	},
};
</script>

<style></style>
