<template>
	<Modal
		v-model="modalAdd"
		title="Importar"
		:fullscreen="false"
		:maxWidth="'70%'"
		:progress="progress.percent"
		:progressLabel="progress.label"
		:load="load"
	>
		<template slot="body" v-if="modalAdd">
			<v-container>
				<v-alert
					class="mt-3"
					text
					type="info"
					border="left"
					style="font-size: 14px;"
					v-if="!logErros.length"
				>
					<ul>
						<li>
							A base utilizada para importação deve ser semelhante
							a base exportada.
						</li>
						<li>
							As colunas <b>codigo_rd</b>, <b>ano</b>,
							<b>mensagem</b> e <b>ativo</b> são obrigatórias.
						</li>
						<li>
							A coluna <b>ativo</b>, informar <b>s</b> ou
							<b>S</b> para ativar ou <b>n</b> ou <b>N</b> para
							desativar a mensagem, qualquer valor diferente , o
							status irá permanecer o mesmo, sem alteração.
						</li>
						<li>
							Se a columa <b>mensagem</b> estiver vazia, a linha
							será desconsiderada.
						</li>
						<li>
							Se houver mais de uma linha com o mesmo
							<strong>codigo_rd</strong> e <strong>ano</strong>, a
							ultima linha será considerada e as anteriores serão
							desconsideradas.
						</li>
					</ul>
				</v-alert>
				<v-form
					ref="form"
					@submit.prevent="submitForm"
					v-if="!logErros.length"
				>
					<v-row>
						<v-col cols="8">
							<v-file-input
								v-model="arquivos"
								:rules="regras"
								:label="'Selecione o arquivo'"
								outlined
								dense
								requerid
								:multiple="false"
								show-size
								:value="arquivos"
								@change="changeFile()"
								accept=".csv"
							></v-file-input>
						</v-col>
						<v-col cols="4" align="right">
							<v-btn
								class="ml-2 mr-5"
								color="primary"
								type="submit"
								:disabled="!formValidate"
								:loading="false"
							>
								<v-icon left>mdi-upload</v-icon> Importar
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
				<v-row no-gutters class="mb-0" v-if="logErros.length">
					<v-col cols="12">
						<v-alert
							class="mt-3"
							text
							prominent
							:type="!efetuados ? 'error' : 'warning'"
							border="left"
						>
							<v-row align="center">
								<v-col class="grow" v-if="!efetuados">
									Falha ao processar a importação, por favor,
									exporte o log para analisar os detalhes.
								</v-col>
								<v-col class="grow" v-if="efetuados">
									{{ efetuados }} registros foram efetuados,
									porém alguns registros não foram
									processados, por favor, exporte o log para
									analisar os detalhes.
								</v-col>
								<v-col class="shrink">
									<download-csv
										:data="logErros"
										:labels="errosLabels"
										name="erros.csv"
										delimiter=";"
									>
										<v-btn
											:color="
												!efetuados ? 'red' : 'warning'
											"
										>
											<v-icon left
												>mdi-microsoft-excel</v-icon
											>
											Exportar Logs
										</v-btn>
									</download-csv>
								</v-col>
							</v-row>
						</v-alert>
					</v-col>
				</v-row>
				<!-- <Overlay :overlay="overlay" /> -->
			</v-container>
		</template>
	</Modal>
</template>

<script>
//import Overlay from "../../simulacao/resumo/_components/Overlay.vue";
import Modal from "../../../components/Modal.vue";

export default {
	components: { Modal },
	props: {
		value: Boolean,
	},
	data() {
		return {
			load: false,
			arquivos: [],
			logErros: [],
			efetuados: 0,
			errosLabels: {
				linha: "Linha",
				ano: "Ano",
				codigo_rd: "Código RD",
				fornecedor: "Fornecedor",
				mensagem: "Mensagem",
				msg: "Log Erro",
			},
			formValidate: false,
			limitFile: 1,
			progress: {
				percent: null,
				label: null,
			},
			regras: [
				(v) => v.length > 0 || "Selecione um arquivo.",
				(v) => {
					let _validate = v.filter(
						(e) =>
							![".CSV"].includes(e.name.toUpperCase().substr(-4))
					);
					return (
						_validate.length == 0 ||
						"O arquivo deve ser do tipo CSV."
					);
				},
			],
		};
	},
	computed: {
		modalAdd: {
			get() {
				return this.value;
			},
			set(v) {
				this.arquivos = [];
				this.logErros = [];
				this.formValidate = false;
				this.efetuados = 0;
				this.progress = {
					percent: null,
					label: null,
				};
				this.$emit("input", v);
			},
		},
		anoAtual() {
			return '2023';
		},
	},
	methods: {
		async submitForm() {
			const validForm = this.$refs.form.validate();
			if (!this.formValidate) return;

			this.progress.percent = null;
			this.progress.label = null;
			this.efetuados = 0;

			const formData = new FormData();
			formData.append("files", this.arquivos[0]);

			var response = {};

			await this.$http().post(
				"/simulacao/informacoesGerais/importar",
				formData,
				{
					onUploadProgress: (event) => {
						let _percent = (event.loaded / event.total) * 10;
						this.progress.percent = _percent;
						this.progress.label = "Upload do arquivo";
					},
					onDownloadProgress: (progressEvent) => {
						let retorno = progressEvent.currentTarget.response.split(
							";"
						);

						let obj = JSON.parse(retorno[retorno.length - 2]);

						this.progress.percent = obj.index;
						this.progress.label = obj.label;

						response.success = obj.success ? obj.success : null;
						response.erros = obj.erros ? obj.erros : null;
						this.efetuados = obj.efetuados || 0;
					},
				}
			);

			if (!response.success) {
				this.logErros = response.erros;
				this.progress.percent = null;
				this.progress.label = null;

				if (this.efetuados)
					this.$emit("filtrar", {
						ano: this.anoAtual,
						fornecedor: [],
					});

				return;
			}

			setTimeout(() => {
				this.modalAdd = false;
				this.$emit("filtrar", { ano: this.anoAtual, fornecedor: null });
			}, 3000);
		},
		changeFile() {
			this.formValidate = this.$refs.form.validate();
		},
	},
	created() {},
};
</script>

<style></style>
