<template>
	<v-container fluid>
		<v-form ref="form" @submit.prevent="submitForm">
			<v-row no-gutters>
				<v-col cols="12">
					<tiptap-vuetify
						v-model="mensagem"
						:extensions="extensions"
						@keydown="onKeyDown"
						:card-props="{
							height: 200,
							activeClass: 'scroll',
						}"
						class="customTip"
					>
					</tiptap-vuetify>
				</v-col>
				<v-col
					cols="10"
					md="10"
					lg="10"
					xl="10"
					class="red--text body-2 mt-1"
				>
					<span v-if="!mensagem">Campo obrigatório</span>
				</v-col>
				<v-col cols="2" class="body-2 mt-1 text-right teal--text">
					{{ countCartacter + " / " + limitCartacter }}
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="2" lg="2" xl="2" class="mb-5 ">
					<v-btn
						class="ml-2 my-1"
						color="primary"
						type="submit"
						:disabled="!mensagem"
						:loading="false"
						right
						absolute
						v-if="itemUpdate == null"
					>
						<v-icon left>mdi-content-save</v-icon> Salvar
					</v-btn>
					<v-btn
						class="ml-2 my-1"
						color="primary"
						:disabled="!mensagem"
						:loading="false"
						right
						absolute
						v-if="itemUpdate != null"
						@click="submitUpdate()"
					>
						<v-icon left>mdi-refresh</v-icon> Atualizar
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
		<Overlay :overlay="overlay" />
	</v-container>
</template>

<script>
import Overlay from "../../simulacao/resumo/_components/Overlay.vue";
import {
	TiptapVuetify,
	Heading,
	Bold,
	Italic,
	Underline,
	BulletList,
	OrderedList,
	ListItem,
	Blockquote,
} from "tiptap-vuetify";

export default {
	components: {
		TiptapVuetify,
		Overlay,
	},
	props: {
		ano: Number,
		fornecedor: Array,
		itemUpdate: {
			type: Object,
			default: () => null,
		},
	},
	data() {
		return {
			overlay: true,
			limitCartacter: 500,
			countCartacter: 0,
			mensagem: (this.$props.itemUpdate || {}).mensagem || "",
			dataFornecedor: (this.$props.fornecedor || []).map((e) => e.value),
			extensions: [
				Blockquote,
				Underline,
				Italic,
				ListItem,
				BulletList,
				OrderedList,
				[
					Heading,
					{
						options: {
							levels: [1, 2, 3],
						},
					},
				],
				Bold,
			],
		};
	},

	methods: {
		getLocalData() {
			return {
				id_fornecedor: this.dataFornecedor,
				dt_inicial: this.ano + "-01-01",
				dt_final: this.ano + "-12-31",
				mensagem: this.mensagem,
			};
		},
		async getBeneficios() {
			this.overlay = true;

			if (this.itemUpdate != null) {
				return (this.overlay = false);
			}

			// const _checa = await this.$http().post(
			// 	"/simulacao/informacoesGerais/valida",
			// 	this.getLocalData()
			// );

			// if ((_checa || { status: 500 }).status != 200) {
			// 	this.$dialog.notify.error(
			// 		"Ops! Falha ao validar os dados, por favor, tente novamente.",
			// 		{
			// 			position: "top-right",
			// 			timeout: 5000,
			// 		}
			// 	);
			// 	this.$emit("modalClose", false);
			// 	this.overlay = false;
			// 	return false;
			// }

			// if (
			// 	_checa.data.length > 0 &&
			// 	_checa.data.length == this.fornecedor.length
			// ) {
			// 	this.$dialog.notify.warning(
			// 		"Já possui benefício cadastrado para os fornecederes e ano selecionados. ",
			// 		{
			// 			position: "top-right",
			// 			timeout: 5000,
			// 		}
			// 	);
			// 	this.$emit("modalClose", false);
			// 	this.overlay = false;
			// 	return false;
			// }

			this.overlay = false;
			let _new = this.dataFornecedor.filter(
				(e) => !_checa.data.includes(e)
			);
			this.dataFornecedor = _new;

			return true;
		},
		async submitForm() {
			// if (!(await this.getBeneficios()) || !this.mensagem) return;

			this.overlay = true;
			const _response = await this.$http().post(
				"/simulacao/informacoesGerais/insert",
				this.getLocalData()
			);

			if ((_response || { status: 500 }).status != 200) {
				this.$dialog.notify.warning(
					"Falha ao cadastrar o benefício por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				this.$emit("modalClose", false);
				this.overlay = false;
				return null;
			}

			this.overlay = false;
			this.$emit("modalClose", false);
			this.$emit("filtrar", {
				fornecedor: this.fornecedor,
				ano: this.ano,
			});
		},
		async submitUpdate() {
			if (!this.mensagem) return;

			this.overlay = true;

			const _response = await this.$http().post(
				"/simulacao/informacoesGerais/update",
				{
					id_fornecedor: this.itemUpdate.id_fornecedor,
					dt_inicial: this.itemUpdate.ano + "-01-01",
					mensagem: this.mensagem,
				}
			);

			if ((_response || { status: 500 }).status != 200) {
				this.$dialog.notify.error(
					`Falha ao atualizar o benefício, por favor, tente novamente.`,
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				this.overlay = false;
				return null;
			}

			this.itemUpdate.mensagem = this.mensagem;

			this.$emit("modalClose");
		},
		count(event) {
			var _texto = event.target.innerText.replace(/\n/g, "");

			if (event.key == "Backspace") {
				_texto = _texto.slice(0, -1);
			} else {
				_texto += event.key;
			}

			this.countCartacter = _texto.length;
		},
		onKeyDown(event, view) {
			this.count(event);
			//console.log(event.keyCode);
			if (
				this.countCartacter > this.limitCartacter &&
				event.keyCode != 8
			) {
				return event.preventDefault();
			}
		},
	},
	created() {
		this.getBeneficios();
	},
};
</script>

<style>
.customTip .tiptap-vuetify-editor__content {
	overflow-y: scroll !important;
	height: 154px;
}
</style>
